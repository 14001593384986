import { NgClass } from '@angular/common'
import { Component, EventEmitter, Input, Output, computed, input } from '@angular/core'
import { MatListModule } from '@angular/material/list'
import { TranslocoModule } from '@ngneat/transloco'

import { ConnectableChannel } from '~features/integrations/models/connectable-channel.model'
import { Channel } from '~features/projects/models/channel.model'

import { AlertComponent } from '../../../ui/alert/alert.component'
import { ChannelCardComponent } from '../channel-card/channel-card.component'

const sortChannels = <T extends Channel | ConnectableChannel>(channels: T[]): T[] =>
  [...channels].sort((a, b) => {
    if (a.connected && !b.connected) {
      return 1
    }
    if (!a.connected && b.connected) {
      return -1
    }
    if (a.name < b.name) {
      return -1
    }
    if (a.name > b.name) {
      return 1
    }
    return 0
  })

@Component({
  selector: 'sb-channel-list',
  standalone: true,
  imports: [MatListModule, ChannelCardComponent, AlertComponent, TranslocoModule, NgClass],
  template: `
    <div class="channels-list" [ngClass]="{ summary: variant() === 'summary' }">
      @if (variant() === 'summary') {
        @for (channel of channels(); track channel) {
          <sb-channel-card variant="cta" [channel]="channel" [removable]="false" transparent />
        }
      } @else {
        <mat-selection-list>
          @for (channel of channels(); track channel) {
            <mat-list-item (click)="onSelectChannel(channel)" [disabled]="channel.connected || !available">
              <sb-channel-card
                [available]="!channel.connected && available"
                [channel]="channel"
                [clickable]="clickable"
                [removable]="false"
                transparent
              />
            </mat-list-item>
          }

          @if (!hasChannels) {
            <mat-list-item disabled>
              <sb-alert variant="neutral">{{ 'words.noResult' | transloco }}</sb-alert>
            </mat-list-item>
          }
        </mat-selection-list>
      }
    </div>
  `,
  styles: `
    .channels-list {
      position: relative;
      margin: 0 auto;
      max-height: 330px;
      overflow-y: auto;
      overflow-x: hidden;
      text-align: left;
    }

    .summary {
      max-height: none;
      display: flex;
      flex-direction: column;
      gap: 30px;
      overflow: visible;
    }
  `,
})
export class ChannelListComponent<T extends Channel | ConnectableChannel> {
  variant = input<'default' | 'summary'>('default')
  @Input() available = true
  @Input() clickable = true
  @Output() selectChannel = new EventEmitter<T>()
  channels = input<T[]>()

  sortedChannels = computed(() => sortChannels(this.channels()))

  get hasChannels(): boolean {
    return this.channels().length > 0
  }

  onSelectChannel(channel: T): void {
    if (!this.clickable || !this.available || channel.connected) {
      return
    }
    this.selectChannel.emit(channel)
  }
}
